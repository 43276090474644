import axios from 'axios';
import router from "@/router/index.js"
import {getStorage,setStorage} from "@/common/utils.js"
// import toastr from 'toastr'
// import 'toastr/build/toastr.css'
import { Message } from 'element-ui'
import { DialogToast } from 'v-dialogs'

// 创建一个独立的axios实例
const service = axios.create({
    // 设置baseUr地址,如果通过proxy跨域可直接填写base地址
    baseURL: 'https://bookapi.ishu.com/',
    // 定义统一的请求头部
    headers: {
        'Content-Type': 'application/json' //默认方式提交数据
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' //表单方式提交数据
    },
    // 配置请求超时时间
    timeout: 10000
});

// 请求拦截
service.interceptors.request.use(config => {
    // 在header中自定义token参数名：tokenHeader，可添加项目token
    // config.headers[tokenHeader] = 'token'
    if(config.isupload){
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    if (getStorage("token")) {
        config.headers['token'] = getStorage("token") // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
         // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改

      }
      config.headers['package'] = 'com.book.pc'
    return config;
});
// 响应拦截
service.interceptors.response.use((response) => {
    // 获取接口返回结果
    const res = response.data;
    if (res.code === 401) {
        setStorage("token",null)
        router.replace({ path: '/login' })
    }
    // code为200，直接把结果返回回去，这样前端代码就不用在获取一次data.
    if (res.code === 200) {
        return res;
    } else if (res.code === 10000) {
        // 10000假设是未登录状态码
        return res;
    } else {

        return res;
    }
        return res;
    }, (error) => {
        // DialogToast('网络请求异常，请稍后重试!',{
        //     messageType:'warning',
        //     closeTime:2,
        //     position:'topCenter'
        // })
        
    });


function request(url, params, options = { loading: true, mock: false, error: true }, method,isupload=false) {
    // let loadingInstance = '';
    // 请求前loading
    // if (options.loading) loadingInstance = Loading.service({background:'transparent'});
    return new Promise((resolve, reject) => {
        let data = {}
        // get请求使用params字段
        if (method == 'get') data = { params }
        // post请求使用data字段
        if (method == 'post') data = { data: params }
        // delete请求使用params字段
        if (method == 'delete') data = { params }
        service({
            url,
            method,
            ...data,
            isupload
        }).then((res) => {
            // 此处作用很大，可以扩展很多功能。对返回的数据进行统一处理
            if (res && res.code === 200) {
                resolve(res.data);
            } else {
                Message.error(res.message)
                if(res.message=='账号不存在，请先完成注册'){
                    router.push({ path: '/register'}); // 在验证码发送成功后跳转到注册页面

                }
              
            }
        }).catch((error) => {
            console.log(error); // 在.catch()中输出错误信息
            reject(error); // 将错误信息传递给调用方
        }).finally(() => {
            // loadingInstance.close();
        })
    })
}

// 封装GET请求
function get(url, params, options) {
    return request(url, params, options, 'get')
}
// 封装POST请求
function post(url, params, options) {
    return request(url, params, options, 'post')
}
// 封装POST请求
function fileupload(url, params, options) {
    return request(url, params, options, 'post',true)
}
// 封装DELETE请求
function Delete(url, params, options) {
    return request(url, params, options, 'delete')
}
export default {
    get, post, Delete,fileupload
}



